import themes from '@/data/themes'
import { CLIENT } from '@/utils/getEnv'
import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import FondasiLogoSVG from '../../assets/svg/fondasi-logo-black.svg'

interface Props {
  bannerSrc: string,
  logoUrl: string | undefined
}

const BannerLoginSmartfren: FC<Props> = ({ logoUrl }) => {
  const images = ['SignInPic_01.svg', 'SignInPic_02.svg', 'SignInPic_03.svg']
  const texts = [
    <BigText >Experience <b>The Most Effective Way</b> To Engage with your customer!</BigText>,
    <BigText >
      <b>Connecting</b> you <b>Personally</b>  to your customer
      <SmallText>And help you to interact with them using the right channels</SmallText>
    </BigText>,
    <BigText>
      <b>Real Time Result</b> in one big picture for all your campaigns
      <SmallText>To be In Control of all your marketing budget to achieve your desired result</SmallText>
    </BigText>

  ]
  const [active, setActive] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setActive(prevState => {
        if (prevState === images.length - 1) {
          return 0
        }
        return prevState + 1
      })
    }, 3000)

    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <ContainerBannerSmartfren>
        <img src={`/smartfren/illustrations/${images[active]}`} />
        <img src={logoUrl} style={{
          position: 'absolute',
          height: '30px',
          width: 'auto',
          top: 20,
          left: 20,
        }} />
        <div style={{
          position: 'absolute',
          transform: 'translateX(50%)',
          right: '50%',
          top: '10vh',
          textAlign: 'center',
          fontWeight: 300,
          minWidth: '68%'
        }}>
          {texts[active]}
        </div>
        <ContainerDots>
          {images.map((_v, idx) => {
            return (
              <div key={idx} onClick={() => setActive(idx)} className={`dot ${idx === active ? 'active' : ''}`} />
            )
          })}
        </ContainerDots>
      </ContainerBannerSmartfren>
    </>
  )
}
const ContainerDots = styled.div`
  display: grid;
  grid-template-columns: repeat(3, max-content);
  gap: 8px;
  position: absolute;
  transform: translateX(50%);
  right: 50%;
  bottom: 70px;
  .dot {
    cursor: pointer;
    width: 18px;
    height: 14px;
    border: 1px solid #D9D9D9;
    background-color: #fff;
    &.active {
      background-color: #FF1659;
    }
  }
`

const ContainerBannerSmartfren = styled.div`
  width: max-content;
  height: 100%;
  position: relative;
  background: #F7F7F7;
  img {
    height: 100vh;
  }
  @media (max-width: 820px) {
    img {
      height: 100%;
      width: 100vw;
    }
  }
`

const BannerLoginBahasa: FC<Props> = ({ bannerSrc }) => {
  return (
    <>
      <ContainerBanner style={{ backgroundImage: `url(${bannerSrc})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
        {/* <Image src={bannerSrc} /> */}
      </ContainerBanner>
      <ContainerLogo>
        <div style={{ background: 'white', padding: '4px 6px 0 6px' }}>
          <FondasiLogoSVG height="45" width="200" />
        </div>
      </ContainerLogo>
    </>
  )
}

const BannerLoginGrahacom: FC<Props> = ({ logoUrl }) => {
  return (
    <>
      <ContainerBanner style={{ backgroundImage: `url(${logoUrl})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
        {/* <Image src={bannerSrc} /> */}
      </ContainerBanner>
    </>
  )
}

const bannerClientMapping = {
  dana: BannerLoginBahasa,
  bahasa: BannerLoginBahasa,
  disdukcapil_jkt: BannerLoginGrahacom,
  smartfren: BannerLoginSmartfren,
  grahacom: BannerLoginGrahacom,
}

const BannerLogin: FC = () => {
  const loginTheme = themes[CLIENT]?.login
  const logoUrl = loginTheme?.logoUrl
  const bannerSrc = themes[CLIENT]?.banner?.src
  const Banner = bannerClientMapping[CLIENT]
  return (
    <>
      <Banner logoUrl={logoUrl} bannerSrc={bannerSrc} />
    </>
  )
}

export default BannerLogin

const ContainerLogo = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
`
export const StyledFormAuth = styled.form`
  /* padding: 16px; */
  max-width: 500px;
`
const ContainerBanner = styled.div`
  /* background: #D5D5D6; */
  width: 50vw;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  @media (max-width: 820px) {
    height: 50vh;
    width: 100vw;
  
  }
`

const BigText = styled.span`
  font-size: 32px;
  @media (max-width: 425px) {
    font-size: 18px;
  }
  @media (max-width: 325px) {
    font-size: 14px;
  }
`

const SmallText = styled.span`
  font-size: 16px;
  display: block;
  margin-top: 8px;

  @media (max-width: 425px) {
    font-size: 12px;
  }
  @media (max-width: 325px) {
    font-size: 10px;
    margin-top: 4px;
  }
`