export default {
  head: {
    icon: '/disdukcapil/small.jpeg',
    text: 'Disdukcapil Jakarta'
  },
  banner: {
    src: '/bahasa/banner.jpg'
  },
  login: {
    logoUrl: '/disdukcapil/large.jpeg',
  },
  header: {
    logo: '/disdukcapil/small.jpeg',
    logoStyle: {
      marginBottom: '20px',
      marginTop: '12px',
      width: '40px',
    },
    background: '#FFFFFF',
    menuActiveColor: '#FA7600',
    menuColor: 'rgba(29, 29, 29, 0.4)',
    menuActiveBackground: 'rgba(251, 31, 91, 0.05)',
    menuBorderRight: '3px solid #FA7600',
  },
  muiTheme: {
    palette: {
      primary: { main: '#2196F3', '100': '#baddfa', '50': '#EDF3F7', 700: 'rgb(64, 135, 191)' },
      secondary: { main: '#2591FF' },
      warning: { main: '#ff9800' }
    }
  },
  botStudio: {
    collapsableLogo: '/fondasi-logo-square.svg',
    collapsableStyle: {
      width: '40px'
    },
    expandedLogo: '/fondasi-logo-square.svg',
    expandedStyle: {
      width: '30px'
    }
  },
  componentStyle: {
    borderRadius: {
      xs: '5px',
      sm: '10px',
    }
  }
}