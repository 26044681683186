// Global initialization things need to be happens here
import { removeCookies } from '@/components/utils/Auth'
import FallbackError from '@/components/utils/FallbackError'
import FondasiAPI from '@/utils/FondasiAPI'
import FondasiAPIv3 from '@/utils/FondasiAPIv3'
import LayanAnalyticsAPI from '@/utils/LayanAnalyticsAPI'
import LayanAPI from '@/utils/LayanAPI'
import { init as initApm } from '@elastic/apm-rum'
import { withTransaction } from '@elastic/apm-rum-react'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import * as Sentry from '@sentry/nextjs'
import 'emoji-mart/css/emoji-mart.css'
import type { AppContext, AppProps } from 'next/app'
import App from 'next/app'
import Head from 'next/head'
import Router, { useRouter, withRouter } from 'next/router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import pkg from 'package.json'
import 'react-aspect-ratio/aspect-ratio.css' // aspect ratio
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import 'react-datetime/css/react-datetime.css'
import { hotjar } from 'react-hotjar'
import ReactModal from 'react-modal'
import { RecoilRoot } from 'recoil'
import 'src/styles/fonts.css' // import fonts
// import GlobalStyles from 'src/styles/GlobalStyles'
import { createGlobalStyle, ThemeProvider, StyleSheetManager } from 'styled-components'
import isPropValid from '@emotion/is-prop-valid'
import { SWRConfig } from 'swr'
import ApplicationContextProvider from '../components/utils/ApplicationProvider'
import LoggedInUserContextProvider from '../components/utils/LoggedInUserProvider'
import SocketInitializationProvider from '../components/utils/SocketInitialization'
import UnreadNotificationProvider from '../components/utils/UnreadNotification'
import themes from '../data/themes'
import getEnv, { APM_SERVER_URL, CLIENT, ENABLE_APM } from '../utils/getEnv'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { GOOGLE_RECAPTCHA_KEY } from '@/utils/constants'
import { useEffect } from 'react'


const { version } = pkg

const SHOW_RECAPTCHA_BADGES = [
  '/login',
]

if (ENABLE_APM && APM_SERVER_URL && getEnv['fondasiAPIBaseURL']) {
  const _apm = initApm({
    // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
    serviceName: 'fondasi-webapp',
    // Set custom APM Server URL (default: http://localhost:8200)
    serverUrl: APM_SERVER_URL,
    // Set service version (required for sourcemap feature)
    serviceVersion: version,
    environment: getEnv['environment'],
    distributedTracingOrigins: [getEnv['fondasiAPIBaseURL'].replace('/api', '')]
  })
}

const GlobalStyle = createGlobalStyle<{ background?: string, secondary?: string }>`
  /* Custom scrollbar */
  /* width */
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track { 
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #D0D6DD;
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, .48);
  }

  ::-webkit-scrollbar-track-piece:start {
    background: transparent;
  }

  ::-webkit-scrollbar-track-piece:end {
    background: transparent;
  }

  body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
  }

  #__next {
    display: flex;
    flex-direction: row;
  }

  #nprogress{
    .bar {
      height: 4px;
      background: ${props => props.background} !important;
    }
    .peg {
      box-shadow: 0 0 10px ${props => props.secondary}, 0 0 5px ${props => props.secondary};
    }
    .spinner-icon {
      border-top-color: ${props => props.background};
      border-left-color: ${props => props.background};
    }
  }

  pre {
    overflow: hidden;
    word-wrap: wrap break-word;
  }
`
// init hotjar for monitoring and reporting
if (getEnv['environment'] !== 'local' && getEnv['layanWebSentryDSN'] && typeof window !== 'undefined') {
  if (getEnv['hotjarAppId'] && getEnv['hotjarAppV']) {
    hotjar.initialize(getEnv['hotjarAppId'], getEnv['hotjarAppV'])
  }
  // Sentry.init({
  //   dsn: getEnv['layanWebSentryDSN'],
  //   environment: getEnv['environment'],
  //   normalizeDepth: 10,
  //   beforeSend: (event, hint) => beforeSendToSentry(event, hint)
  // })
}

// configure progesrs bar on top off app
NProgress.configure({ trickleSpeed: 50 })

Router.events.on('routeChangeStart', () => {
  NProgress.start()
  NProgress.inc(.24)
})

Router.events.on('routeChangeComplete', () => {
  NProgress.done()
})

Router.events.on('routeChangeError', () => {
  NProgress.done()
})

const LayanTheme = createTheme({
  palette: {
    primary: {
      main: '#2196F3',
      50: '#4da9f5',
      100: '#3fa0f0',
      200: '#319df5',
      500: '#2196F3',
      600: '#2591FF',
      700: '#1186fc',
      900: '#037ef8'
    },
    secondary: { main: '#2591FF' },
    warning: { main: '#ff9800' }
  }
})

type FondasiAppProps = {
  clientName?: string,
  applicationDetail?: any,
  clientPartner?: string
}


function LayanApp({ Component, pageProps }: AppProps<FondasiAppProps>) {
  // const { application } = this.props.router.query
  const route = useRouter()
  const application = route.query.application
  LayanAPI.setBaseURL(`${getEnv['layanAPIBaseURL']}/${application}`)
  FondasiAPI.setBaseURL(`${getEnv['fondasiAPIBaseURL']}/v2/${application}`)
  FondasiAPIv3.setBaseURL(`${getEnv['fondasiAPIBaseURL']}/v3`, application)
  LayanAnalyticsAPI.setBaseURL(`${getEnv['layanAnalyticsAPIBaseURL']}/${application}/analytics`)
  // setup modal to root component
  ReactModal.setAppElement('#__next')
  const workspaceName = pageProps.clientName || pageProps.applicationDetail?.title
  const key = CLIENT || pageProps.applicationDetail?.client
  const customTheme = themes[key]?.muiTheme ? createTheme(themes[key]?.muiTheme) : undefined
  const currThemeHead = themes[CLIENT || pageProps.applicationDetail?.client || pageProps.clientPartner]?.head

  useEffect(() => {
    let retryCount = 0
    let timeoutRef: NodeJS.Timer

    const findCaptchaBadge = () => {
      if (retryCount >= 10) return

      const el = document.querySelector<HTMLElement>('.grecaptcha-badge')
      if (el) {
        el.style.visibility = SHOW_RECAPTCHA_BADGES.some((path) => path === route.pathname) ? 'visible' : 'hidden'
        return
      }

      retryCount += 1

      timeoutRef = setTimeout(findCaptchaBadge, 1000)
    }
    findCaptchaBadge()

    return () => {
      clearTimeout(timeoutRef)
    }
  }, [route.pathname])

  return (
    <>
      <Head>
        {
          currThemeHead ?
            <>
              <link rel="shortcut icon" href={currThemeHead.icon} />
              <title>{workspaceName} | {currThemeHead.text}</title>
            </> :
            pageProps.clientPartner === 'dam' ?
              <>
                <link rel="shortcut icon" href="/DAM-favicon.svg" />
                <title>DAM Omnichannel</title>
              </> :
              <>
                <link rel="shortcut icon" href="/Fondasi-favicon.ico" />
                {/* TODO: webapp title */}
                <title>{workspaceName} | Fondasi</title>
              </>
        }
      </Head>
      <GlobalStyle background={customTheme?.palette?.primary?.main || '#2196F3'} secondary={customTheme?.palette?.secondary?.main || '#2591FF'} />
      {/* <GlobalStyles background={customTheme?.palette?.primary?.main || '#2196F3'} secondary={customTheme?.palette?.secondary?.main || '#2591FF'}/> */}
      <MuiThemeProvider theme={customTheme || LayanTheme}>
        <Sentry.ErrorBoundary
          fallback={({ error }) => {
            return <FallbackError error={error} />
          }}
        >
          <LoggedInUserContextProvider>
            <SocketInitializationProvider>
              <UnreadNotificationProvider>
                <ApplicationContextProvider>
                  <ThemeProvider theme={themes[key] || {}}>
                    <RecoilRoot>
                      <SWRConfig value={{
                        onError: (error) => {
                          if (error?.response?.status === 401) {
                            removeCookies()
                            route.push('/login')
                          }
                        }
                      }}>
                        <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA_KEY}>
                          <StyleSheetManager
                            shouldForwardProp={(propName, elementToBeRendered) => {
                              return isPropValid(propName)
                            }}
                          >
                            <Component {...pageProps} />
                          </StyleSheetManager>
                        </GoogleReCaptchaProvider>
                      </SWRConfig>
                    </RecoilRoot>
                  </ThemeProvider>
                </ApplicationContextProvider>
              </UnreadNotificationProvider>
            </SocketInitializationProvider>
          </LoggedInUserContextProvider>
        </Sentry.ErrorBoundary>
      </MuiThemeProvider>

    </>
  )
}


LayanApp.getInitialProps = async (context: AppContext) => {
  // setup initialization on server-side rendering
  const { application } = context.router.query
  LayanAPI.setBaseURL(`${getEnv['layanAPIBaseURLforSSR']}/${application}`, { ssr: true })
  LayanAnalyticsAPI.setBaseURL(`${getEnv['layanAnalyticsAPIBaseURLforSSR']}/${application}/analytics`, { ssr: true })
  FondasiAPI.setBaseURL(`${getEnv['fondasiAPIBaseURLforSSR']}/v2/${application}`)
  FondasiAPIv3.setBaseURL(`${getEnv['fondasiAPIBaseURL']}/v3`, application)
  const appProps = await App.getInitialProps(context)
  console.log(appProps, 'appProps')
  console.log('running _app getInitial props')
  return { ...appProps }
}
const WithRouterApp = withRouter(LayanApp)
export default withTransaction('_appComponent', 'component')(WithRouterApp)